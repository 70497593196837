<template>
	<div class="mrescondicionFormHeader puiformheader">
		<v-row dense>
			<v-col cols="12" lg="2">
				<pui-field :label="$t('header.mrescondicion.idconcod')" :value="getIdconcodValue"></pui-field>
			</v-col>
			<v-col cols="12" lg="2">
				<pui-field :label="$t('header.mrescondicion.codconresedi')" :value="getCodconresediValue"></pui-field>
			</v-col>
			<v-col cols="12" lg="4">
				<pui-field :label="$t('header.mrescondicion.name')" :value="getNameValue"></pui-field>
			</v-col>
			<v-col cols="12" lg="4">
				<pui-field :label="$t('header.mrescondicion.nombre')" :value="getNombreValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mrescondicion-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mrescondicion'
		};
	},
	computed: {
		getIdconcodValue() {
			return this.model && this.model.idconcod ? this.model.idconcod : '-';
		},
		getCodconresediValue() {
			return this.model && this.model.codconresedi ? this.model.codconresedi : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
		getNombreValue() {
			return this.model && this.model.nombre ? this.model.nombre : '-';
		},
	}
}
</script>
